import React, { useState } from 'react'
import CardTireModel from '../../../common/components/CardTireModel/CardTireModel'
import { Neumaticos } from '../../../common/components/FichasNeumaticos/types'
import { t } from '../../../common/i18n'
import ButtonGrid from '../ListFilter/ButtonGrid'
import FilterButton from '../ListFilter/FilterButton'
import styles from './index.module.scss'
import _ from 'lodash'

type SeccionNeumaticoProps = {
  marca: string
  logoMarca: string
  neumaticos: Neumaticos[]
}

const SeccionNeumaticoPorGama = ({ marca, logoMarca, neumaticos }) => {
  const groupedByGama = _.groupBy(neumaticos, 'gama')
  const sortedGamas = Object.keys(groupedByGama).sort()

  return (
    <>
      {sortedGamas.map((gama) => {
        const gamaNeumaticos = groupedByGama[gama]

        return (
          <div key={gama} className="grupo-gama">
            <CardTireModel
              marca={marca}
              logoMarca={logoMarca}
              neumaticos={_.uniqBy(gamaNeumaticos, 'slug')} // Ensure unique tire models
              title={_.capitalize(gama)} // The title is the gama
            />
          </div>
        )
      })}
    </>
  )
}

const SeccionNeumaticoMoto: React.FC<SeccionNeumaticoProps> = ({
  marca,
  logoMarca,
  neumaticos,
}) => {
  const [activeFilter, setActiveFilter] = useState<string>('todos')

  const handleFilterClick = (filter: string) => {
    setActiveFilter(filter === activeFilter ? 'todos' : filter)
  }

  const uniqueGamas = _.uniqBy(neumaticos, 'gama').map((neumatico) => ({
    gama: neumatico.gama,
    label: neumatico.gama ? _.capitalize(neumatico.gama.toLowerCase()) : null,
    total: neumaticos.filter((n) => n.gama === neumatico.gama).length,
  }))

  const neumaticosData = (filter): Neumaticos[] => {
    if (filter === 'todos') return neumaticos as unknown[] as Neumaticos[]
    return neumaticos.filter((n) => n.gama === filter)
  }

  const activeNeumaticos = neumaticosData(activeFilter)

  return (
    <>
      <div className={styles.filter_group}>
        <ButtonGrid>
          <FilterButton
            key="todos"
            label={t('neumaticos_filtros.todos')}
            total={neumaticos.length}
            isActive={activeFilter === 'todos'}
            onClick={() => handleFilterClick('todos')}
          />
          {uniqueGamas.map((filter) => (
            <FilterButton
              key={filter.gama}
              label={filter.label}
              total={filter.total}
              isActive={activeFilter === filter.gama}
              onClick={() => handleFilterClick(filter.gama)}
            />
          ))}
        </ButtonGrid>
      </div>

      {activeFilter !== 'todos' && activeNeumaticos.length > 0 && (
        <CardTireModel
          marca={marca}
          logoMarca={logoMarca}
          neumaticos={activeNeumaticos}
        />
      )}
      {activeFilter === 'todos' && (
        <SeccionNeumaticoPorGama
          marca={marca}
          logoMarca={logoMarca}
          neumaticos={neumaticos}></SeccionNeumaticoPorGama>
      )}
    </>
  )
}
export default SeccionNeumaticoMoto
